import React from "react";
import "../../styles/newsletter.css";

const Newsletter = () => {
  return (
    <section className="newsletter">

    </section>
  );
};

export default Newsletter;
